import { PUBLIC_REQUEST_PRODUCT } from '@eq/pages/api/requests/[uid]'
import { cn } from '@eq/utils/classnames'
import { formatBytes } from '@eq/utils/formatBytes'
import { Tooltip } from 'antd'
import Image from 'next/image'
import { useMemo } from 'react'

type ATTACHMENT = NonNullable<PUBLIC_REQUEST_PRODUCT['attachments']>[number]

interface AttachmentProps extends ATTACHMENT {
  className?: string
}

// 'xlsx' | 'xls' | 'jpg' | 'jpeg' | 'pdf' | 'png' | 'csv' | 'doc' | 'docx'
const AttachmentIcon = (props: { href: string; extension: ATTACHMENT['extension'] }) => {
  const src = useMemo(() => {
    if (['jpg', 'jpeg', 'png'].includes(props.extension)) {
      return props.href
    }
    const fileName = ['xlsx', 'xls'].includes(props.extension)
      ? 'excel'
      : ['doc', 'docx'].includes(props.extension)
      ? 'word'
      : props.extension

    return `/images/document-types/${fileName}.svg`
  }, [props.extension, props.href])
  return <Image src={src} width={100} height={100} layout="responsive" objectFit="cover" quality={100} />
}

export default function Attachment(props: AttachmentProps) {
  return (
    <a
      download={props.originalFilename}
      href={`/api/files/${props.path}`}
      className={cn(
        'attachment-component col-span-1 text-black  px-2 py-1 rounded flex space-x-2 items-center hover:border-info',
        props.className
      )}
    >
      <div className="h-full w-8 rounded-md overflow-hidden flex-shrink-0">
        <AttachmentIcon href={`/api/files/${props.path}`} extension={props.extension} />
      </div>
      <div className="flex-1 flex flex-col truncate leading-tight flex-shrink w-auto">
        <Tooltip title={props.originalFilename}>
          <span className="truncate overflow-hidden">{props.originalFilename}</span>
        </Tooltip>
        <p className="mb-0">
          <small className="text-gray-400">{formatBytes(props.size)}</small>
        </p>
      </div>
    </a>
  )
}
