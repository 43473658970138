import BrandImage from '@eq/components/BrandImage'
import OutlinedLabel from '@eq/components/OutlinedLabel'

interface Props {
  qty?: number
  tax?: number
  brand?: {
    name?: string
    image?: string
    slug?: string
  }
  notes: string
}

export type ToolsProductVariantProps = Omit<Sanity.Schema.ToolsProduct, keyof Props> & Props

export default function ToolsProductVariant(props: ToolsProductVariantProps) {
  return (
    <div>
      <div className="flex space-x-2">
        <div className="tools-product-component flex-grow flex space-x-2">
          <BrandImage
            name={props?.brand?.name || ''}
            src={props?.brand?.image || ''}
            width={60}
            height={60}
            fallbackText={
              <>
                <span className="not-sr-only">Any</span>
                <span className="sr-only">Any Brand</span>
              </>
            }
            initialsFallback
            objectFit="contain"
          />

          <OutlinedLabel label={`Description`} className="w-full flex-grow">
            <div className="truncate">
              {[props.title, props.description, props.productCode].filter(Boolean).join(' - ')}{' '}
              {props.brand?.name && <span className="text-gray-400 italic">({props.brand?.name})</span>}
            </div>
          </OutlinedLabel>
        </div>
        {Boolean(props.qty) && (
          <OutlinedLabel label="QTY" className="min-w-14">
            {props.qty}
          </OutlinedLabel>
        )}
      </div>
      {props.notes}
    </div>
  )
}

export const LabeledProductField = ({ label, children, className = '' }) => {
  return (
    <div className={className}>
      <label className="md:text-gray-400 font-normal px-1 md:text-xs underline">{label}</label>
      <div>{children}</div>
    </div>
  )
}
