import { logger } from '@eq/utils/logger'
import {
  ExchangeMethodTypes,
  MachineryConditionsTypes,
  MachineryManufacturerTypes,
  ProductVariantTypes,
  QuotationStatusTypes,
  RequestStatusTypes,
} from '@eezyquote/server.schema'
import { defineMessage, defineMessages, MessageDescriptor } from 'react-intl'

const unknown = defineMessage({ defaultMessage: 'unknown' })

/**
 * This helps to ensure a fallback method if messages are not defined correctly in the database
 * if the fallback method is triggered, we want it to output an error, because that means that
 * some of our data is corrupted, but this will still allow the frontend to work properly
 */
function messageHelper<T extends string>(label: T, messages: Record<T, MessageDescriptor>) {
  if (Object.keys(messages).includes(label)) {
    return messages[label]
  }
  const errorMessage = `The label "${label}" is not valid for the defined messages: [${Object.keys(messages).join(', ')}]`
  if (process.env.NODE_ENV === 'development') {
    throw new Error(errorMessage)
  } else {
    logger.clientError(`[i18n/common]: ${errorMessage}`)
  }
  return unknown as MessageDescriptor
}

export const machineryConditionLabel = (label: MachineryConditionsTypes) => {
  return messageHelper(
    label,
    defineMessages({
      new: { defaultMessage: 'New' },
      reconditioned: { defaultMessage: 'Reconditioned' },
      used: { defaultMessage: 'Used' },
    })
  )
}

export const machineryConditionDescription = (label: MachineryConditionsTypes) => {
  return messageHelper(
    label,
    defineMessages({
      new: { defaultMessage: 'New spare part from the manufacturer' },
      reconditioned: {
        defaultMessage:
          'Rebuilt or Reconditioned - The unit undergoes manual dismantling; housing could be cleaned; all non-functional parts are replaced with both new, most common, non-original parts and previously used, but re-tested and cleaned, parts. Parts which are subject to intensive wear and tear, such as ball bearings, bushings, brushes, etc. are replaced in their entirety',
      },
      used: { defaultMessage: 'Sourced from faulty non-functional units' },
    })
  )
}

export const machineryManufacturerLabel = (label: MachineryManufacturerTypes) => {
  return messageHelper(
    label,
    defineMessages({
      oem: { defaultMessage: 'OEM' },
      aftermarket: { defaultMessage: 'Aftermarket' },
    })
  )
}

export const machineryManufacturerDescription = (label: MachineryManufacturerTypes) => {
  return messageHelper(
    label,
    defineMessages({
      oem: {
        defaultMessage:
          "OEM (Original Equipment Manufacturer) are manufacturer parts purchased by another company and retailed under that purchasing company's brand name. OEM refers to the company that originally manufactured the machinery. With the exception of packaging, the part is identical to the original.",
      },
      aftermarket: {
        defaultMessage:
          'Any non-OEM manufacturers. These parts are produced by companies other than the original manufacturer. Parts are made to fit and perform as well as the original ones. Aftermarket companies buy the rights to reproduce the parts, and supply them to the same wholesale distributors of OEM parts. These parts are generally produced with the same machinery and materials. Because after market parts wre not designed in the same manner, they may have minor differences in appearance and feel. Quality of the part depends on the manufacturer.',
      },
    })
  )
}

export const machineryMessages = {
  conditions: {
    labels: machineryConditionLabel,
    descriptions: machineryConditionDescription,
  },
  manufacturer: {
    labels: machineryManufacturerLabel,
    descriptions: machineryManufacturerDescription,
  },
}

export const requestStatusMessages = (label: RequestStatusTypes) => {
  return messageHelper(
    label,
    defineMessages({
      active: { defaultMessage: 'Active' },
      closed: { defaultMessage: 'Closed' },
    })
  )
}

export const quotationStatusMessages = (label: QuotationStatusTypes) => {
  return messageHelper(
    label,
    defineMessages({
      pending: { defaultMessage: 'Pending' },
      cancelled: { defaultMessage: 'Cancelled' },
      accepted: { defaultMessage: 'Accepted' },
    })
  )
}

export const productMessages = (label: ProductVariantTypes) => {
  return messageHelper(
    label,
    defineMessages({
      machineryProduct: { defaultMessage: 'Machinery' },
      transportProduct: { defaultMessage: 'Transport' },
      genericProduct: { defaultMessage: 'Other' },
      toolsProduct: { defaultMessage: 'Tools' },
    })
  )
}

export const exchangeMethodMessages = (label: ExchangeMethodTypes) => {
  return messageHelper(
    label,
    defineMessages({
      delivery: { defaultMessage: 'Quote for Delivery' },
      collect: { defaultMessage: 'Quote for Collection' },
    })
  )
}

export const measurementShortMessages = (
  label: Sanity.Schema.LengthMeasurement['unit'] | (Sanity.Schema.WeightMeasurement['unit'] | Sanity.Schema.VolumeMeasurement['unit'])
) => {
  if (!label) defineMessage({ defaultMessage: '-' })
  return messageHelper(
    label as NonNullable<typeof label>,
    defineMessages({
      millimeter: { defaultMessage: 'mm' },
      centimeter: { defaultMessage: 'cm' },
      meter: { defaultMessage: 'm' },
      kilometer: { defaultMessage: 'km' },
      tonne: { defaultMessage: 't' },
      kilogram: { defaultMessage: 'kg' },
      gram: { defaultMessage: 'g' },
      milligram: { defaultMessage: 'mg' },
    })
  )
}

export const measurementLongMessages = (
  label: Sanity.Schema.LengthMeasurement['unit'] | (Sanity.Schema.WeightMeasurement['unit'] | Sanity.Schema.VolumeMeasurement['unit'])
) => {
  if (!label) defineMessage({ defaultMessage: '-' })
  return messageHelper(
    label as NonNullable<typeof label>,
    defineMessages({
      millimeter: { defaultMessage: 'millimeter' },
      centimeter: { defaultMessage: 'centimeter' },
      meter: { defaultMessage: 'meter' },
      kilometer: { defaultMessage: 'kilometer' },
      tonne: { defaultMessage: 'tonne' },
      kilogram: { defaultMessage: 'kilogram' },
      gram: { defaultMessage: 'gram' },
      milligram: { defaultMessage: 'milligram' },
    })
  )
}
