import OutlinedLabel from '@eq/components/OutlinedLabel'
import { machineryMessages } from '@eq/i18n/common'
import { pick } from 'lodash'
import { useIntl } from 'react-intl'

interface MachineryProductVariantProps extends Sanity.Schema.MachineryProduct {
  qty?: number
  tax?: number
  // note?: string
}

export default function MachineryProductVariant(props: MachineryProductVariantProps) {
  const intl = useIntl()

  const part = Object.values(pick(props.part, ['partNumber', 'description'])).filter(Boolean)
  const equipment = Object.values({ ...pick(props.equipment?.brand, ['name']), ...pick(props.equipment, ['model', 'description']) }).filter(
    Boolean
  )

  const hasPartTitle = part.length > 0
  const hasEquipmentTitle = equipment.length > 0
  const hasConditions = Boolean(props?.part?.conditions?.[0])
  const hasManufacturer = Boolean(props?.part?.manufacturer?.[0])

  return (
    <div className="flex">
      <div className="machinery-product-component w-full grid grid-cols-2 gap-2 lg:grid-cols-12 relative flex-grow">
        {hasEquipmentTitle && (
          <OutlinedLabel label={'Equipment'} className="w-full lg:w-auto col-span-2 lg:col-span-4  ">
            <div className="truncate">{equipment.join(', ')}</div>
          </OutlinedLabel>
        )}
        {hasPartTitle && (
          <OutlinedLabel label={`Part`} className="w-full lg:w-auto col-span-2 lg:col-span-3  ">
            <div className="truncate">{part.join(', ')}</div>
          </OutlinedLabel>
        )}
        {hasConditions && (
          <OutlinedLabel label="Conditions" className="w-full lg:w-auto  col-span-1 lg:col-span-2">
            <div className="max-w-full w-full truncate">
              {props.part?.conditions ? (
                props.part?.conditions?.map((condition) => intl.formatMessage(machineryMessages.conditions.labels(condition))).join(', ')
              ) : (
                <span className="text-xs text-gray-600 pl-1">Non specified</span>
              )}
            </div>
          </OutlinedLabel>
        )}
        {hasManufacturer && (
          <OutlinedLabel label="Manufacturer" className="w-full lg:w-auto col-span-1 lg:col-span-2">
            <div className="truncate">
              {props.part?.manufacturer ? (
                props.part?.manufacturer
                  ?.map((manufacturer) => intl.formatMessage(machineryMessages.manufacturer.labels(manufacturer)))
                  .join(', ')
              ) : (
                <span className="text-xs text-gray-600 pl-1">Non specified</span>
              )}
            </div>
          </OutlinedLabel>
        )}
      </div>
      {Boolean(props.qty) && (
        <OutlinedLabel label="QTY" className={'min-w-14'}>
          {props.qty}
        </OutlinedLabel>
      )}
    </div>
  )
}

export const LabeledProductField = ({ label, children, className = '' }) => {
  return (
    <div className={className}>
      <label className="md:text-gray-400 font-normal px-1 md:text-xs underline">{label}</label>
      <div>{children}</div>
    </div>
  )
}
