import Attachment from '@eq/components/Attachment'
import { PUBLIC_REQUEST_PRODUCT } from '@eq/pages/api/requests/[uid]'
import { isGenericProduct, isMachineryProduct, isToolsProduct, isTransportProduct } from '@eezyquote/server.schema'
import { Skeleton } from 'antd'
import GenericProductVariant from './GenericProductVariant'
import MachineryProductVariant from './MachineryProductVariant'
import ToolsProductVariant, { ToolsProductVariantProps } from './ToolsProductVariant'
import TransportProductVariant from './TransportProductVariant'

type REQUEST_PRODUCT_TYPE = NonNullable<Sanity.Schema.Requests['products']>[number]
type QUOTED_PRODUCT_TYPE = NonNullable<Sanity.Schema.Quotations['products']>[number]

/**
 * The extended variant is due to sanity still not supporting union fields,
 * https://sanity-io-land.slack.com/archives/C9Z7RC3V1/p1617340480427600?thread_ts=1617339219.427400&cid=C9Z7RC3V1
 * so in the query we return `variant[0]` because there will always only be one item in the variant
 */
type PRODUCT_TYPE_EXTENDED_VARIANT = Omit<
  REQUEST_PRODUCT_TYPE | QUOTED_PRODUCT_TYPE | PUBLIC_REQUEST_PRODUCT,
  'variant' | 'attachments'
> & {
  variant:
    | REQUEST_PRODUCT_TYPE['variant'][number]
    | QUOTED_PRODUCT_TYPE['variant']
    | PUBLIC_REQUEST_PRODUCT['variant']
    | PUBLIC_REQUEST_PRODUCT['variant']
  attachments?: PUBLIC_REQUEST_PRODUCT['attachments']
}

interface GeneralProductsProps extends PRODUCT_TYPE_EXTENDED_VARIANT {
  className?: string
  qty: number
}

type ATTACHMENT = NonNullable<PRODUCT_TYPE_EXTENDED_VARIANT['attachments']>[number]

export const Attachments = ({ fileList = [] }: { fileList?: Array<ATTACHMENT> }) => {
  return (
    <div className="mt-4 max-w-full grid md:grid-cols-[80px,1fr]">
      <h4 className="mr-2 mb-1 text-gray-500">Attached :</h4>
      <ul className="list-none grid gap-5 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 pl-0">
        {fileList.map((file, i) => {
          return (
            <li key={i}>
              <Attachment {...file} className="opacity-75 hover:opacity-100 " />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default function GeneralProducts({ className = '', ...product }: GeneralProductsProps) {
  return (
    <div className={`general-product-variant-component max-w-full ${className}`}>
      {isMachineryProduct(product.variant) ? (
        <MachineryProductVariant {...product.variant} qty={product.qty} />
      ) : isTransportProduct(product.variant) ? (
        <TransportProductVariant {...product.variant} />
      ) : isToolsProduct<ToolsProductVariantProps>(product.variant) ? (
        <ToolsProductVariant {...product.variant} qty={product.qty} />
      ) : isGenericProduct(product.variant) ? (
        <GenericProductVariant {...product.variant} />
      ) : (
        <Skeleton.Button active className="w-screen-xs max-w-full" />
      )}
      {/* {md || isTransport ?
        <Variant {...(product.variant as any)} qty={product.qty as never} />
      ) : (
        <div className="flex justify-between">
          <Tooltip
            overlay={<div className="p-2 relative">{<Variant {...(product.variant as any)} qty={product.qty as never} />}</div>}
            className="cursor-pointer truncate max-w-full font-medium"
            overlayClassName="backdrop-filter backdrop-blur backdrop-brightness-10"
            overlayStyle={{ width: 400, maxWidth: '100vw' }}
            placement="topLeft"
          >
            <span>
              <BsInfoCircle /> Product details
            </span>
          </Tooltip>
          {product.qty && (
            <div>
              <span className="text-gray-700">QTY:</span> <span className="font-bold">{product.qty}</span>
            </div>
          )}
        </div>
      )} */}
    </div>
  )
}
