import { round } from 'lodash'

export const bytesToMegaBytes = (bytes, decimal = undefined as undefined | number) => {
  if (typeof decimal === 'undefined') return bytes / 1024 / 1024
  return round(bytes / 1024 / 1024, decimal)
}

export function formatBytes(bytes = 0, decimals = 0) {
  if (bytes == 0) return '0 Bytes'
  const k = 1024,
    dm = decimals || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
