interface GenericProductVariantProps extends Sanity.Schema.GenericProduct {}

export default function GenericProductVariant(props: GenericProductVariantProps) {
  return (
    <div className="generic-product-variant pl-3 leading-tight">
      <div className="truncate font-medium capitalize">{props.label}</div>
      <div className="truncate text-gray-500 text-xs">{props.description}</div>
    </div>
  )
}
