import { cn } from '@eq/utils/classnames'

export default function OutlinedLabel({ label, children, bordered = true, className = '', accent = false }) {
  return (
    <div className={cn('pt-3 outlined-label-component', className)}>
      <div
        className={cn('border relative rounded-md pb-2', {
          'border-transparent': !bordered,
          'bg-accent-1': accent,
        })}
      >
        <dt className={`text-gray-400 font-medium text-xs -mt-2 px-1 mx-2 w-max absolute label`}>
          <span className="relative">{label}</span>
        </dt>
        <dd className={cn('px-3 pt-2 mb-0')}>{children}</dd>
      </div>
      <style jsx>
        {`
          .label::before {
            content: '';
            bottom: 8px;
            @apply absolute h-px left-0 w-full;
            background: ${accent ? '#fafafa' : '#fff'};
          }
        `}
      </style>
    </div>
  )
}
