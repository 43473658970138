import { Collapse, CollapsePanelProps, CollapseProps } from 'antd'
import OutlinedLabel from '@eq/components/OutlinedLabel'
import { groupBy } from 'lodash'
import { PUBLIC_REQUEST } from '@eq/pages/api/requests/[uid]'
import { ReactNode, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { isMachineryProduct, isToolsProduct, isTransportProduct } from '@eezyquote/server.schema'
import GeneralProducts from '../GeneralProducts'
import { TransportPublicListLayout } from '../GeneralProducts/TransportProductVariant'
import Attachment from '@eq/components/Attachment'
import { cn } from '@eq/utils/classnames'

interface ProductListProps extends Pick<PUBLIC_REQUEST, 'products' | 'transport'> {
  title?: ReactNode
  collapse?: boolean
  collapseProps?: CollapseProps
  collapsePanelProps?: Partial<CollapsePanelProps>
}

export default function ProductList({ title, collapsePanelProps, collapseProps, collapse = true, ...props }: ProductListProps) {
  const intl = useIntl()
  const isMixedRequest = Object.keys(groupBy(props.products, (product) => product.variant._type)).length > 1
  const isMachinerySector = !isMixedRequest && isMachineryProduct(props?.products[0]?.variant._type)
  const isTransportSector = !isMixedRequest && isTransportProduct(props?.products[0]?.variant._type)
  const isToolsSector = !isMixedRequest && isToolsProduct(props?.products[0]?.variant._type)

  const ProductListItems = useMemo(() => {
    if (isTransportSector) {
      return (
        <TransportPublicListLayout
          transport={props.transport}
          totalWeight={props?.products?.reduce((acc, curr) => {
            const { qty = 0, weight = 0 } = curr.variant as Sanity.Schema.TransportProduct
            return acc + qty * weight
          }, 0)}
          totalVolume={props?.products?.reduce((acc, curr: any) => {
            const variant: Sanity.Schema.TransportProduct = curr.variant
            return acc + variant.height * variant.width * variant.length
          }, 0)}
        >
          <OutlinedLabel label="Goods" bordered={false}>
            <div className="border border-gray-200 rounded-md divide-y divide-gray-200 pl-0 -mx-3 -mt-2 bg-accent-1">
              {props.products.map((product, i) => (
                <GeneralProducts key={`_public-request-list-item-${i}`} {...product} />
              ))}
            </div>
          </OutlinedLabel>
        </TransportPublicListLayout>
      )
    } else {
      return (
        <div className="divide-y -mx-4 -my-4 rounded-sm overflow-hidden">
          {props.products.map((product, i) => (
            <div key={`_public-request-list-item-${i}`} className="even:bg-accent-1 pb-4 pt-3 px-4">
              <GeneralProducts className="pb-2" {...product} />
              {product.attachments && (
                <ul className="list-none grid gap-5 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 pl-0">
                  {product.attachments.map((attachment, i) => (
                    <li key={i}>
                      <Attachment {...attachment} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      )
    }
  }, [isTransportSector, props.products, props.transport])

  if (collapse) {
    const count = props.products.length
    const panelTitle = title
      ? title
      : isMixedRequest
      ? intl.formatMessage({ defaultMessage: `Products ({count})` }, { count })
      : isTransportSector
      ? intl.formatMessage({ defaultMessage: 'Transportation ({count, plural, one {# item} other {# goods}})' }, { count })
      : isMachinerySector
      ? intl.formatMessage({ defaultMessage: `Machinery ({count})` }, { count })
      : isToolsSector
      ? intl.formatMessage({ defaultMessage: `Tools ({count})` }, { count })
      : intl.formatMessage({ defaultMessage: `Products ({count})` }, { count })

    return (
      <div className="public-request-product-list-component divide-y">
        <Collapse defaultActiveKey={'products'} expandIconPosition="right" {...collapseProps}>
          <Collapse.Panel
            key="products"
            header={<div className="font-medium">{panelTitle}</div>}
            {...collapsePanelProps}
            className={cn('overflow-hidden', collapsePanelProps?.className)}
          >
            {ProductListItems}
          </Collapse.Panel>
        </Collapse>
        <style jsx global>{`
          .public-request-product-list-component .ant-collapse {
            @apply overflow-hidden;
          }
          .public-request-product-list-component .ant-collapse-header {
            @apply bg-black rounded-b-none text-white !important;
          }
        `}</style>
      </div>
    )
  }
  return <div className="public-request-product-list-component divide-y">{ProductListItems}</div>
}
