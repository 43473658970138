import { ReactNode } from 'react'

interface NoteProps {
  note?: string
  tag?: boolean
  prefix?: ReactNode
}

const parser = (note?: string) => {
  if (!note) return note
  if (typeof note !== 'string') return note
  return note.split('\n').filter(Boolean)
}

/**
 * ```
 * // example
 * <Note note={record.notes} />
 * ```
 * Ensures the notes styling is applied globally,
 * > In the future we might change this to `markdown` or `html`
 */
export default function Note(props: NoteProps) {
  const parsedNotes = parser(props.note)
  if (!parsedNotes) return null
  if (Array.isArray(parsedNotes)) {
    return (
      <NoteComponent tag={props.tag}>
        {parsedNotes.map((str, i) => (
          <p key={i} className="mb-0">
            {str}
          </p>
        ))}
      </NoteComponent>
    )
  }
  return <NoteComponent tag={props.tag}>{parsedNotes}</NoteComponent>
}

const NoteComponent = ({ children, tag = false, prefix = 'Note:' }) => {
  if (tag)
    return (
      <div className="p-2 inline-flex items-baseline space-x-2  rounded">
        <span className="font-medium mr-2 text-gray-800">{prefix}</span> <span>{children}</span>
      </div>
    )
  return <>{children}</>
}

Note.parser = parser
