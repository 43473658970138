import Image, { ImageProps } from 'next/image'
import { ReactNode } from 'react'

type Props = {
  initialsFallback?: boolean
  src?: ImageProps['src']
  name: string
  fallbackText?: string | ReactNode
}
type BrandImageProps = Props & Omit<ImageProps, keyof Props>

const geInitials = (name: string) => {
  let initials = name
    .split(' ')
    .map((word) => word[0])
    .join('')

  if (initials.length > 1) {
    initials = initials.slice(0, 2)
  } else {
    initials = [name[0], name[1]].filter(Boolean).join('')
  }

  return initials.toUpperCase()
}
export default function BrandImage({ initialsFallback, fallbackText, name, ...props }: BrandImageProps) {
  if (initialsFallback) {
    return (
      <div style={{ width: props.width, height: props.height }}>
        {props.src ? (
          <Image alt={name} {...(props as any)} />
        ) : (
          <div className="w-full h-full bg-accent-2 rounded-full flex place-content-center justify-center items-center">
            <span className="text-gray-400 text-center leading-none font-medium">{name ? geInitials(name) : fallbackText || 'Brand'}</span>
          </div>
        )}
      </div>
    )
  } else if (props.src) {
    return <Image alt={name} {...(props as any)} />
  } else {
    return null
  }
}
