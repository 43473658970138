import OutlinedLabel from '@eq/components/OutlinedLabel'
import { PUBLIC_REQUEST } from '@eq/pages/api/requests/[uid]'
import { ReactNode } from 'react'
import { VscPackage } from 'react-icons/vsc'
import { symbols } from '@eq/utils/symbols'

interface TransportProductVariant extends Sanity.Schema.TransportProduct {
  title?: string
}

export default function TransportProductVariant(props: TransportProductVariant) {
  return (
    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
      <div className="w-0 flex-1 flex items-center">
        <VscPackage className="flex-shrink-0 h-5 w-5 text-gray-400" />
        <div className="ml-2 truncate w-1/3">{props.description || 'Item'}</div>
        <span className="ml-2 flex-1 w-0 truncate">
          <span className="text-xs text-gray-400 ml-4">
            {props.width * props.height * props.length} {symbols.volume}
          </span>
          <span className="text-xs border px-1 text-gray-400 ml-4 truncate overflow-hidden rounded">
            {props.nonStackable ? 'Non-Stackable' : 'Stackable'}
          </span>
          {props.palletized && <span className="text-xs border px-1 text-gray-400 ml-2 truncate overflow-hidden rounded">Palletized</span>}
        </span>
      </div>
      <div className="ml-4 flex-shrink-0">
        {props.weight} kg x <span className="font-medium text-indigo-600 hover:text-indigo-500">{props.qty}</span>
      </div>
    </li>
  )
}

export const RequestTransportDetails = (
  props: Pick<PUBLIC_REQUEST, 'transport'> & { totalWeight: number; totalVolume?: number; className?: string }
) => {
  return (
    <dl className={props.className}>
      <div className="gap-4 lg:gap-2 grid lg:grid-cols-2">
        <OutlinedLabel accent label="Origin">
          ({props.transport?.origin.country}) {props?.transport?.origin.label}
        </OutlinedLabel>
        <OutlinedLabel accent label="Destination">
          ({props.transport?.destination.country}) {props?.transport?.destination.label}
        </OutlinedLabel>
      </div>
      <OutlinedLabel accent label="Total weight">
        {props.totalWeight} kg
      </OutlinedLabel>
      {props.totalVolume && (
        <OutlinedLabel accent label="Total Volume">
          {props.totalVolume} {symbols.volume}
        </OutlinedLabel>
      )}

      <OutlinedLabel accent label="Requires Permit">
        {props.transport?.requiresPermit ? 'Yes' : 'No'}
      </OutlinedLabel>
      <OutlinedLabel accent label="Dangerous Goods">
        {props.transport?.hasDangerousGoods ? 'Yes' : 'No'}
      </OutlinedLabel>
    </dl>
  )
}

export const TransportPublicListLayout = (
  props: Pick<PUBLIC_REQUEST, 'transport'> & { totalWeight: number; totalVolume?: number; children: ReactNode }
) => {
  return (
    <div className="md:grid grid-cols-3 gap-4">
      <RequestTransportDetails className="md:cols-span-1 space-y-4" {...props} />
      <div className="md:col-span-2">{props.children}</div>
    </div>
  )
}
